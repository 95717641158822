import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./en.json";
import translationDE from "./de.json";
import translationFR from "./fr.json";

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  },
  fr: {
    translation: translationFR
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "de",
    keySeparator: ".", // access deep keys like​ 'application.name'
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    returnObjects: true
  });

export default i18n;
