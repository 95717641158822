import React from "react";
import { withTranslation } from "react-i18next";
import validator from "validator";
import { Button, Icon, Modal, Form, Popup } from "semantic-ui-react";
import { Waiting } from "./../helpers/Waiting.jsx";
import request from "./../helpers/request.js";

class PwResetImpl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      step: "enter email",
      email: "",
      msg: ""
    };
  }

  componentWillUnmount() {}

  componentDidUpdate() {}

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () =>
    this.setState({
      modalOpen: false,
      step: "enter email",
      email: "",
      msg: ""
    });

  onResetOk = result => {
    this.setState({
      step: "ok",
      msg: this.props.t("forms.user.reset.ok")
    });
  };

  onResetError = error => {
    if (error.status === 404) {
      this.setState({
        step: "enter email",
        msg: this.props.t("forms.user.reset.notFound")
      });
    } else {
      this.setState({
        step: "error",
        msg: "error (" + error.status + ")"
      });
    }
  };

  handleReset = () => {
    this.setState(
      { step: "sending" },
      request(
        "POST",
        "usr/reset",
        { email: this.state.email },
        this.onResetOk,
        this.onResetError
      )
    );
  };

  handleChange = (e, input) => {
    this.setState({
      email: input.value,
      msg: ""
    });
  };

  render() {
    const { t } = this.props;
    let buttonFkt = this.handleReset;
    let content = "";
    let actions = "";

    if (this.state.step === "sending") {
      content = <Waiting test={t("forms.user.reset.sending")} />;
    }

    if (this.state.step === "enter email") {
      content = (
        <>
          {t("forms.user.reset.info")}
          <div style={{ paddingTop: "2rem" }}>
            <Form>
              <Form.Group>
                <Form.Field key={"email"} width={16}>
                  <Form.Input
                    id={"email"}
                    error={!validator.isEmail(this.state.email)}
                    label={t("forms.user.reset.label.email")}
                    value={this.state.email}
                    onChange={this.handleChange}
                    required={true}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </div>
          <div style={{ color: "#cc0000" }}>{this.state.msg}</div>
        </>
      );
      actions = (
        <Button
          primary
          disabled={!validator.isEmail(this.state.email)}
          onClick={buttonFkt}
        >
          {t("forms.user.reset.button.reset")}
        </Button>
      );
    }

    if (this.state.step === "ok") {
      content = <div>{this.state.msg}</div>;
    }

    if (this.state.step === "error") {
      content = <div>{this.state.msg}</div>;
    }

    return (
      <Modal
        closeIcon
        trigger={
          <div>
            <span className="DRlink" onClick={this.handleOpen} tabIndex="5">
              {t("forms.user.reset.open") + " "}
              <Popup
                style={{ backgroundColor: "transparent" }}
                trigger={
                  <Icon name={"question circle outline"} color={"blue"} />
                }
                content={t("forms.user.reset.help")}
              />
            </span>
          </div>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        centered={false}
      >
        <Modal.Header
          style={{
            borderTop: "1px solid white",
            borderBottom: "1px solid #888888",
            backgroundColor: "rgb(217, 232, 235)",
            padding: "1em"
          }}
        >
          <span className="modalHeader">{t("forms.user.reset.title")}</span>
        </Modal.Header>
        <Modal.Content
          style={{
            borderTop: "1px solid white",
            borderBottom: "1px solid #888888",
            backgroundColor: "rgb(217, 232, 235)",
            padding: "1em"
          }}
        >
          {content}
        </Modal.Content>
        <Modal.Actions
          style={{
            borderTop: "1px solid white",
            borderBottom: "1px solid #888888",
            backgroundColor: "rgb(217, 232, 235)",
            padding: "1em"
          }}
        >
          {actions}
        </Modal.Actions>
      </Modal>
    );
  }
}
const PwReset = withTranslation()(PwResetImpl);
export { PwReset };
