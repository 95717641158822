import React, { useEffect, useContext } from "react";
import { UserContext } from "./helpers/UserContext.jsx";

import { useTranslation } from "react-i18next";
const ls = require("local-storage");

function AppSelect(props) {
  const { t } = useTranslation();
  const context = useContext(UserContext);
  let colors = t("applicationColors", true);

  useEffect(() => {
    //console.log("useEffect", context);
    //console.log("useEffect", props);
  });

  const redirect = function(app, relativePath) {
    let href;
    let tmp = window.location.href.split(".digital-result.cloud/");

    if (app === "Administration") {
      app = "ADM";
    }
    if (app === "Monitoring") {
      app = "MON";
    }
    let lastLocation = ls.get("DR-" + app);
    if (!lastLocation) {
      lastLocation = "";
    } else {
      lastLocation = "#" + lastLocation;
    }
    if (tmp.length > 1) {
      // 'select app' application is served from [customer].digital-result.cloud/apps
      // we replace /apps/ by new app
      const domainPath = !relativePath ? app.toLowerCase() : relativePath.toLowerCase();
      href =
        tmp[0] +
        ".digital-result.cloud/" +
        domainPath +
        "/index.html" +
        lastLocation;
        window.history.pushState({}, "", window.location.href);
        window.location.replace(href);
    } else {
      // test local
      console.log(
        "local: kein wechsel location auf ...",
        app.toLowerCase() + "/index.html" + lastLocation
      );
    }
  };

  let apps = [];
  let appButtons = [];
  for (let i = 0; i < context.roles.length; i++) {
    let r = context.roles[i];
    let found = apps.find(e => {
      return e.name === r.app;
    });
    if (!found) {
      apps.push({ name: r.app, relativePath: r.relative_path });
    }
  }
  apps.sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });

  for (let i = 0; i < apps.length; i++) {
    let found = colors.find(e => {
      return e.app === apps[i].name;
    });
    let style = {
      backgroundColor: "#dddddd",
      color: "#000000"
    };
    if (found) {
      style.backgroundColor = found.background;
      style.color = found.text;
      let appclass = "appButton " + apps[i].name.toLowerCase();
      appButtons.push(
        <div
          key={found.name + "_" + i}
          className={appclass}
          style={style}
          onClick={e => redirect(apps[i].name, apps[i].relativePath)}
        >
          <span>
            {apps[i].name === "Administration" ? "ADM" : apps[i].name}
          </span>
        </div>
      );
    } else {
      console.log(
        "!found - check applicationname in DB (case-sensitive!)",
        apps[i]
      );
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        //justifyContent: "space-evenly",
        backgroundColor: "rgb(217,232,235)",
        marginTop: "-3px"
      }}
    >
      {appButtons}
    </div>
  );
}

export { AppSelect };
