import axios from "axios";
const ls = require("local-storage");

let location = window.location;

let host = location.hostname;
if (location.hostname.indexOf(".digital-result.cloud") > 0) {
  host = location.hostname;
} else {
  // Test local
  host = "umbau.digital-result.cloud";
}

const handleRequestError = (error, method, ressource, errFun) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (errFun) {
      errFun(error.response);
    } else {
      window.console.log(
        "error during request " + method + " on " + ressource + ":",
        error.response
      );
    }
    return;
  }

  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    let response = error.request;
    if (
      error.request.status === 0 &&
      error.request.readyState === 4 &&
      error.request.responseText === ""
    ) {
      response = { status: 408, message: "Request Timeout" };
    }
    if (errFun) {
      errFun(response);
    } else {
      window.console.log(
        "error during request " + method + " on " + ressource + ":",
        response
      );
    }
    return;
  }

  if (error.message) {
    // Something happened in setting up the request that triggered an Error
    if (errFun) {
      errFun(error.message);
    } else {
      window.console.log(
        "error during request " + method + " on " + ressource + ":",
        error.message
      );
    }
    return;
  }

  // unexpected error
  window.console.log(
    "unexpected error during request " + method + " on " + ressource + ":",
    error
  );
};

const server = axios.create({
  //withCredentials: true,
  baseURL: location.protocol + "//" + host + "/api",
  timeout: 10000 /*,  headers: { "Access-Control-Allow-Credentials": true }*/
});

export default function request(method, ressource, workload, okFun, errFun) {
  switch (method) {
    case "GET":
      server
        .get(ressource)
        .then(response => {
          okFun(response.data, response.headers);
        })
        .catch(function(error) {
          handleRequestError(error, method, ressource, errFun);
        });
      break;
    case "POST":
      if (!workload.sessionid) {
        workload.sessionid = ls.get("DR-session");
      }
      server
        .post(ressource, workload)
        .then(response => {
          if (response.data.sessionid) {
            ls.set("DR-session", response.data.sessionid);
          }
          okFun(response.data, response.headers);
        })
        .catch(function(error) {
          handleRequestError(error, method, ressource, errFun);
        });
      break;
    case "PUT":
      if (!workload.sessionid) {
        workload.sessionid = ls.get("DR-session");
      }
      server
        .put(ressource, workload)
        .then(response => {
          if (response.data.sessionid) {
            ls.set("DR-session", response.data.sessionid);
          }
          okFun(response.data, response.headers);
        })
        .catch(function(error) {
          handleRequestError(error, method, ressource, errFun);
        });
      break;
    default:
      handleRequestError(
        { message: "unhandled method - not implemented" },
        method,
        ressource,
        errFun
      );
  }
}
