import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "../helpers/withRouter";
import { Button, Icon, Modal, Form, Popup } from "semantic-ui-react";
import { Password } from "./Password.jsx";
import { Message } from "./../helpers/Message.jsx";
import request from "./../helpers/request.js";

class ActivateImpl extends React.Component {
  constructor(props) {
    super(props);

    this.passwordRef = React.createRef();

    this.state = {
      activationkey: "",
      name: this.props.user || "",
      new_password: "",
      modalOpen: false,
      inRequest: false,
      requestResult: " ",
      requestError: false,
      ok: false,
      timer: null,
      hidepw: true,
      first: true
    };
  }

  componentDidMount() {
    this.setState({
      modalOpen: this.props.activationkey.length > 0,
      activationkey: this.props.activationkey,
      first: false
    });
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => {
    this.setState({ modalOpen: false }, this.props.history.push("/"));
  };

  onActivateOk = account => {
    let timer = setTimeout(() => {
      this.handleClose();
      this.props.onOK(account);
    }, 3000);
    this.setState({
      requestResult: this.props.t("forms.user.activate.ok"),
      requestError: false,
      inRequest: false,
      ok: true,
      timer: timer
    });
  };

  onActivateError = err => {
    console.log("onActivateError", err);
    let requestResult = this.props.t("forms.user.activate.error");
    if (err.status === 404) {
      requestResult = this.props.t("forms.user.activate.errorNoUser");
    }
    this.setState({
      inRequest: false,
      requestResult: requestResult,
      requestError: true
    });
  };

  handleActivate = () => {
    this.setState(
      {
        inRequest: true,
        requestResult: this.props.t("forms.activate.sendingRequest")
      },
      request(
        "POST",
        "usr/activate",
        {
          key: this.state.activationkey,
          password: this.state.new_password
        },
        this.onActivateOk,
        this.onActivateError
      )
    );
  };

  validKey = uuid => {
    let rUuid = /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;
    let match = uuid.match(rUuid);
    if (match && match[0] === uuid) {
      return true;
    }
    return false;
  };

  validPassword = pw => {
    let rPw = /^[^:#?@]{8,20}$/;
    let match = pw.match(rPw);
    if (match && match[0] === pw) {
      return true;
    }
    return false;
  };

  handleChange = (e, input) => {
    switch (input.id) {
      case "new_password":
        this.setState({
          new_password: input.value,
          requestError: false,
          requestResult: " "
        });
        break;
      case "activationkey":
        this.setState({
          activationkey: input.value,
          requestError: false,
          requestResult: " "
        });
        break;
      default:
    }
  };

  toggleShowPw = () => {
    this.setState({ hidepw: !this.state.hidepw });
  };

  handleKey = e => {
    let code = e.keyCode || e.which;
    if (code === 13) {
      if (
        this.validKey(this.state.activationkey) &&
        this.validPassword(this.state.new_password)
      ) {
        this.handleActivate();
      }
    }
  };

  render() {
    const { t } = this.props;
    let content = (
      <>
        {t("forms.user.activate.info")}
        <Form style={{ padding: "1rem" }}>
          <Form.Group>
            <Form.Field key={"activationkey"} width={8}>
              <Form.Input
                id={"activationkey"}
                error={!this.validKey(this.state.activationkey)}
                label={t("forms.user.activate.label.key")}
                defaultValue={this.state.activationkey}
                onChange={this.handleChange}
                required={true}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field key={"keynew_password"} width={8}>
              <div
                ref={this.passwordRef}
                style={{
                  display: "inline-block",
                  width: "calc(100% - 2rem)",
                  marginRight: "0.5rem"
                }}
              >
                <Password
                  id={"new_password"}
                  error={!this.validPassword(this.state.new_password)}
                  label={t("forms.user.activate.label.password")}
                  value={this.state.new_password}
                  onChange={this.handleChange}
                  onKeyUp={this.handleKey}
                />
              </div>
              <Popup
                hoverable
                style={{ backgroundColor: "transparent" }}
                trigger={
                  <Icon name={"question circle outline"} color={"blue"} />
                }
                content={t("forms.user.activate.validPassword")}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </>
    );

    return (
      <>
        <Modal
          closeIcon
          trigger={
            <span
              className="DRlink"
              onClick={this.handleOpen}
              onTouchStart={this.handleOpen}
              tabIndex="6"
            >
              {t("forms.user.activate.open") + " "}
              <Popup
                style={{ backgroundColor: "transparent" }}
                trigger={
                  <Icon name={"question circle outline"} color={"blue"} />
                }
                content={t("forms.user.activate.help")}
              />
            </span>
          }
          open={this.state.modalOpen}
          onClose={this.handleClose}
          centered={false}
        >
          <Modal.Header
            style={{
              borderTop: "1px solid white",
              borderBottom: "1px solid #888888",
              backgroundColor: "rgb(217, 232, 235)",
              padding: "1em"
            }}
          >
            <span className="modalHeader">
              {t("forms.user.activate.title")}
            </span>
          </Modal.Header>
          <Modal.Content
            style={{
              borderTop: "1px solid white",
              borderBottom: "1px solid #888888",
              backgroundColor: "rgb(217, 232, 235)",
              padding: "1em"
            }}
          >
            {content}
            {this.state.requestError ? (
              <Message
                className={"message error"}
                text={this.state.requestResult}
              />
            ) : (
              ""
            )}
          </Modal.Content>
          <Modal.Actions
            style={{
              borderTop: "1px solid white",
              borderBottom: "1px solid #888888",
              backgroundColor: "rgb(217, 232, 235)",
              padding: "1em"
            }}
          >
            <Button
              primary
              disabled={
                !this.validKey(this.state.activationkey) ||
                !this.validPassword(this.state.new_password) ||
                this.state.requestResult !== " " ||
                this.state.inRequest
              }
              onClick={this.handleActivate}
            >
              {t("forms.user.activate.button.activate")}
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
const ActivateRouted = withRouter(ActivateImpl);
delete ActivateRouted.contextType; // https://stackoverflow.com/questions/53240058/use-hoist-non-react-statics-with-withrouter
const Activate = withTranslation()(ActivateRouted);
export { Activate };
