import React from "react";
import { Form } from "semantic-ui-react";

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: true
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  toggleHide = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ hide: !this.state.hide });
  };

  handleChange = (e, input) => {
    if (this.props.onChange) {
      this.props.onChange(e, input);
    } else {
    }
  };

  handleKeyUp = e => {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e);
    } else {
    }
  };

  handleBlur = e => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    } else {
    }
  };

  render() {
    return (
      <div style={{ display: "inline" }}>
        <Form.Input
          id={this.props.id}
          name={this.props.id}
          error={this.props.error}
          label={this.props.label}
          autoComplete={this.props.id}
          fluid
          placeholder={this.props.placeholder}
          defaultValue={this.props.defaultValue}
          type={this.state.hide ? "password" : "text"}
          icon={{
            link: true,
            name: "eye",
            onMouseOver: this.toggleHide,
            onMouseOut: this.toggleHide,
            onTouchStart: this.toggleHide,
            onTouchEnd: this.toggleHide
          }}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          onBlur={this.handleBlur}
          tabIndex={this.props.tabIndex ? this.props.tabIndex : 3}
          required={true}
        />
      </div>
    );
  }
}
export { Password };
