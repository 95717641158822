import React from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import { withRouter } from "./helpers/withRouter";
import { Profile } from "./user/Profile.jsx";
import { AppSelect } from "./AppSelect.jsx";
const ls = require("local-storage");

class MainImpl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {}

  /*<Route path="/test" render={props => <ListProcess />} />*/

  render() {
    return (
      <Routes>
        <Route path="/" exact element={<AppSelect />}>
        </Route>
        <Route path="/profile" element={<Profile />} />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
    );
  }
}

const Main = withRouter(MainImpl);

export { Main };
