import React from "react";
import { HashRouter } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/es";
import "moment/locale/de";
import i18n from "./i18n/i18n.jsx";
import { UserContext } from "./components/helpers/UserContext.jsx";
import request from "./components/helpers/request.js";
import { Login } from "./components/user/Login.jsx";
import { Header } from "./components/Header.jsx";
import { Main } from "./components/Main.jsx";
import "semantic-ui-css/semantic.min.css";
import "./css/App.scss";
const ls = require("local-storage");

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    moment.locale(i18n.language);
    let dt = "no buildtime set";
    if (process.env.REACT_APP_BUILDDATE) {
      let tmp = process.env.REACT_APP_BUILDDATE.split(",")[0];
      dt = moment(tmp).format("YYYY-MM-DD HH:mm");
    }

    this.state = {
      buildtime: dt,
      user: null,
      loginName: "",
      roles: [],
      rights: { menu: ["select", "profile"] },
      mounted: false,
      urlParams: [],
      functions: {
        saveProfile: this.saveProfile
      },
      t: 0,
      apps: []
    };
  }

  componentDidMount() {
    if (window.location && window.location.search) {
      let urlParams = this.state.urlParams;
      let tmp = window.location.search.replace("?", "").split("&");
      for (let i = 0; i < tmp.length; i++) {
        let param = tmp[i].split("=");
        if (param.length === 2) {
          urlParams.push({ key: param[0], value: param[1] });
        }
      }
      this.setState({ urlParams: urlParams, mounted: true });
    }

    if (!this.state.user) {
      request(
        "POST",
        "usr/ping",
        {
          info: "check if user is logged in, get accepted language otherwise"
        },
        this.pingOK,
        error => {
          console.log("ERROR", error);
        }
      );
    }
  }

  componentDidUpdate() {
    if (this.state.user && this.state.user.d_uuid) {
      if (this.state.roles.length === 0 && !this.state.error) {
        request(
          "POST",
          "usr",
          { what: "roles", info: "load all possible roles" },
          this.getRolesOK,
          this.getRolesError
        );
      }
    } else {
      request(
        "POST",
        "usr/ping",
        {
          info: "check if user is logged in, get accepted language otherwise"
        },
        this.pingOK,
        error => {
          console.log("ERROR", error);
        }
      );
    }
  }

  pingOK = (response, headers) => {
    if (!headers["accept-language"]) {
      headers["accept-language"] =
        "de-DE,de;q=0.9,fr;q=0.8,en-US;q=0.7,en;q=0.6";
    }
    let cmp =
      "," +
      headers["accept-language"].replace("-", ",") +
      " (if response has no languages we take: ,en,de,fr,... in this order";
    let de = cmp.indexOf(",de");
    let en = cmp.indexOf(",en");
    let fr = cmp.indexOf(",fr");

    let languages = [
      { key: "de", val: de },
      { key: "en", val: en },
      { key: "fr", val: fr }
    ];
    languages.sort((a, b) => {
      if (a.val < b.val) return -1;
      if (a.val > b.val) return 1;
      return 0;
    });

    let language = languages[0].key;
    i18n.changeLanguage(language, () => {
      moment.locale(language);
    });

    if (response === "no users" || response === "bootstrap ok") {
      this.setState({ language: language, bootstrap: true, mounted: true });
    } else {
      if (response.user && response.user.d_uuid) {
        this.setUser(response.user);
      } else {
        this.setState({
          language: language,
          mounted: true
        });
      }
    }
  };

  redirect = (app, allApps) => {
    let href;
    let tmp = window.location.href.split(".digital-result.cloud/");

    if (app === "Administration") {
      app = "ADM";
    }
    if (app === "Monitoring") {
      app = "MON";
    }
    let lastLocation = ls.get("DR-" + app);
    if (!lastLocation) {
      lastLocation = "";
    } else {
      lastLocation = "#" + lastLocation;
      if (allApps.length === 1) {
        lastLocation = '';
      }
    }
    if (tmp.length > 1) {
      const domainPath = !allApps[0].relativePath ? app.toLowerCase() : allApps[0].relativePath.toLowerCase();
      href =
        tmp[0] +
        ".digital-result.cloud/" +
        domainPath +
        "/index.html" +
        lastLocation;
      window.history.pushState({}, "", window.location.href);
      window.location.replace(href);

    } else {
      console.log("cannot login from local");
    }
  };

  setSession = user => {
    ls.set("DR-session", user._sessionid);
  }

  setUser = user => {
    let apps = [];
    if (user.customerroles) {
      for (let i = 0; i < user.customerroles.length; i++) {
        let r = user.customerroles[i];
        let found = apps.find(e => {
          return e.name === r.app;
        });
        if (!found) {
          apps.push({ name: r.app, relativePath: r.relative_path });
        }
      }
      if (apps.length === 1) {
        console.log("setUser only 1 app");
        this.redirect(apps[0].name, apps);
      }
    }

    this.setState({
      user: user,
      roles: user.customerroles,
      mounted: true,
      apps: apps
    });
  };

  logout = text => {
    ls.remove("DR-session");
    request(
      "POST",
      "usr/ping",
      { info: "logout" },
      response => {
        this.setState({ user: null });
      },
      error => {
        console.log("error", error);
      }
    );
  };

  saveProfile = profile => {
    profile.d_uuid = this.state.user.d_uuid;
    request(
      "POST",
      "usr/profile",
      { info: "save profile", profile: profile },
      result => {
        let user = this.state.user;
        user.display_name = result.display_name;
        user.email_new = result.email_new;
        user.language = result.language;
        this.setState({ user: user, t: this.state.t + 1 });
      },
      error => {
        console.log("error saving profile", error);
      }
    );
  };

  activationClose = () => {
    this.setState({ urlParams: [] });
  };

  setAccountName = name => {
    this.setState({ loginName: name, urlParams: [] });
  };

  render() {
    if (!this.state.mounted || this.state.apps.length === 1) {
      return "";
    }
    let content = "";
    if (!this.state.user || !this.state.user.d_uuid) {
      content = (
        <Login
          key="login"
          setSession={this.setSession}
          setUser={this.setUser}
          text={this.state.logoutText}
          build={this.state.buildtime}
        />
      );
    } else {
      content = (
        <>
          <Header build={this.state.buildtime} configs={this.state.configs} />
          <Main />
        </>
      );
    }

    return (
      <HashRouter basename="/" location>
        <UserContext.Provider
          key="userctx"
          value={{
            build: this.state.buildtime,
            user: this.state.user,
            logout: this.logout,
            roles: this.state.roles,
            rights: this.state.rights,
            functions: this.state.functions,
            tableconfigs: this.state.tableconfigs,
            filterAp: this.state.filterAp
          }}
        >
          {content}
        </UserContext.Provider>
      </HashRouter>
    );
  }
}

export default App;
