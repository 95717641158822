import React from "react";

class Waiting extends React.Component {
  render() {
    return (
      <>
        <div className="loader" />
        <div className="loaderBottom">
          {this.props.text ? this.props.text : "waiting..."}
        </div>
      </>
    );
  }
}

export { Waiting };
