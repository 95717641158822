import React from "react";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import moment from "moment";
import { UserContext } from "./../helpers/UserContext.jsx";
import { Button, Container, Form, Input } from "semantic-ui-react";
import { DrSaveButton } from "./../helpers/buttons/DrSaveButton.jsx";
import validator from "validator";

class ProfileImpl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      display_name: "",
      salutation_title: "",
      salutation_firstname: "",
      salutation_familyname: "",
      email: "",
      email_new: "",
      language: "de",
      special_rights_tags: "",
      inRequest: false,
      screen: null
    };
  }

  componentDidMount() {
    let user = JSON.parse(JSON.stringify(this.context.user));
    let screen = this.checkDevice();
    window.addEventListener("resize", this.handleResize);

    this.setState({
      name: user.name,
      display_name: user.display_name,
      salutation_title: user.salutation_title || "",
      salutation_firstname: user.salutation_firstname || "",
      salutation_familyname: user.salutation_familyname || "",
      email: user.email,
      email_new: user.email_new,
      language: user.language,
      screen: screen,
      special_rights_tags: user.special_rights_tags || ""
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.inRequest) {
      this.setState({ inRequest: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  checkDevice = () => {
    let w = window.innerWidth;
    let h = window.innerHeight;
    let orientation = "landscape";
    if (h > w) {
      orientation = "portrait";
    }
    return { w: w, h: h, orientation: orientation };
  };

  handleResize = () => {
    let screen = this.checkDevice();
    if (screen.w !== this.state.screen.w || screen.h !== this.state.screen.h) {
      this.setState({ screen: screen });
    }
  };

  setLang = language => {
    i18n.changeLanguage(language, () => {
      moment.locale(language);
    });
    this.setState({ language: language, changed: true });
  };

  changeEmail = () => {};

  onChange = (e, input) => {
    switch (input.id) {
      case "display_name":
        this.setState({ display_name: input.value });
        break;
      case "email_new":
        this.setState({ email_new: input.value });
        break;
      case "salutation_title":
        this.setState({ salutation_title: input.value });
        break;
      case "salutation_firstname":
        this.setState({ salutation_firstname: input.value });
        break;
      case "salutation_familyname":
        this.setState({ salutation_familyname: input.value });
        break;
      default:
    }
  };

  saveProfile = () => {
    this.setState(
      { changed: false },
      this.context.functions.saveProfile({
        display_name: this.state.display_name,
        email_new: this.state.email_new,
        language: this.state.language,
        salutation_title: this.state.salutation_title,
        salutation_firstname: this.state.salutation_firstname,
        salutation_familyname: this.state.salutation_familyname
      })
    );
  };

  makeRolesDisplay = () => {
    let entries = [];
    let colors = this.props.t("applicationColors", true);
    let foundColor;

    let roles = [];
    for (let i = 0; i < this.context.user.customerroles.length; i++) {
      roles.push(this.context.user.customerroles[i]);
    }
    roles.sort((a, b) => {
      let aCmp = a.app + a.role;
      let bCmp = b.app + b.role;
      if (aCmp.toUpperCase() > bCmp.toUpperCase()) return 1;
      if (aCmp.toUpperCase() < bCmp.toUpperCase()) return -1;
      return 0;
    });

    let app = "";
    for (let i = 0; i < roles.length; i++) {
      let color = "#000000";
      let background = "#ffffff";
      foundColor = colors.find(e => {
        return e.app === roles[i].app;
      });
      if (foundColor) {
        color = foundColor.text;
        background = foundColor.background;
      }
      if (roles[i].app !== app) {
        app = roles[i].app;
        entries.push(
          <div
            key={"app_" + i}
            style={{
              borderTop: "1px solid white",
              padding: "0.5em 0 0.5em 0.5em",
              backgroundColor: background,
              color: color,
              fontWeight: "bold"
            }}
          >
            {roles[i].app}
          </div>
        );
      }
      entries.push(
        <div
          key={"app_role" + i}
          style={{
            padding: "0.25em 0 1em 2em",
            backgroundColor: background,
            color: color,
            fontWeight: "normal"
          }}
        >
          {roles[i].role}
        </div>
      );
    }

    return (
      <div
        className="scrollTableWrapper"
        style={{
          marginTop: "0.5em",
          maxHeight: "15rem",
          overflowY: "scroll"
        }}
      >
        {entries}
      </div>
    );
  };

  render() {
    const { t } = this.props;

    if (this.state.name === "" || !this.state.screen) {
      return "";
    }

    let tags = this.state.special_rights_tags.split(",");
    let taglist = [];
    for (let i = 0; i < tags.length; i++) {
      let text = tags[i].trim();
      if (text.length > 0) {
        taglist.push(
          <span key={"tag" + i} className="tag">
            {text}
          </span>
        );
      }
    }

    const validChars = /[a-z0-9. \- äöüßáàéèíìóòúùůýñňřšžâæçèéêëîïôœùûüÿ αβγδεζηθικλμνξοπρστυφχψω śźż]{3,20}/i;
    let display_name = this.state.display_name || "";
    let match = display_name.match(validChars);
    let nameOk = (match && match[0] === display_name) || display_name === "";

    let emailOK =
      validator.isEmail(this.state.email_new || "") || !this.state.email_new;

    let user = JSON.parse(JSON.stringify(this.context.user));
    let changed =
      user.display_name !== display_name ||
      user.salutation_title !== this.state.salutation_title ||
      user.salutation_firstname !== this.state.salutation_firstname ||
      user.salutation_familyname !== this.state.salutation_familyname ||
      user.email_new !== this.state.email_new ||
      user.language !== this.state.language;

    let enClass = "drFlag english";
    let deClass = "drFlag german";
    if (this.state.language === "en") {
      enClass += " active";
    }
    if (this.state.language === "de") {
      deClass += " active";
    }

    let roles = [];
    for (let i = 0; i < this.context.user.customerroles.length; i++) {
      roles.push(this.context.user.customerroles[i]);
    }
    roles.sort((a, b) => {
      let aCmp = a.app + a.role;
      let bCmp = b.app + b.role;
      if (aCmp.toUpperCase() > bCmp.toUpperCase()) return 1;
      if (aCmp.toUpperCase() < bCmp.toUpperCase()) return -1;
      return 0;
    });

    let rolesDisplay = this.makeRolesDisplay();

    let formGroups = [];
    formGroups.push(
      <Form.Group key="name" style={{ marginTop: "0.5em" }}>
        <Form.Field inline width={2}>
          <label htmlFor={"name"}>{t("forms.profile.name")}</label>
          <Input disabled={true} id="name" value={this.state.name} />
        </Form.Field>
        <Form.Field inline width={3} error={!nameOk}>
          <label htmlFor={"display_name"}>
            {t("forms.profile.display_name")}
          </label>
          <Input
            id="display_name"
            value={display_name}
            placeholder={this.state.name}
            onChange={this.onChange}
          />
        </Form.Field>
        <Form.Field width="1" />
        <Form.Field inline width={2}>
          <label htmlFor={"salutation_title"}>
            {t("forms.profile.salutation_title")}
          </label>
          <Input
            id="salutation_title"
            value={this.state.salutation_title}
            onChange={this.onChange}
          />
        </Form.Field>
        <Form.Field inline width={4}>
          <label htmlFor={"salutation_firstname"}>
            {t("forms.profile.salutation_firstname")}
          </label>
          <Input
            id="salutation_firstname"
            value={this.state.salutation_firstname}
            onChange={this.onChange}
          />
        </Form.Field>
        <Form.Field inline width={4}>
          <label htmlFor={"salutation_familyname"}>
            {t("forms.profile.salutation_familyname")}
          </label>
          <Input
            id="salutation_familyname"
            value={this.state.salutation_familyname}
            onChange={this.onChange}
          />
        </Form.Field>
      </Form.Group>
    );

    formGroups.push(
      <Form.Group key="email" style={{ marginTop: "0.5em" }}>
        <Form.Field inline width={4}>
          <label htmlFor={"email"}>{t("forms.profile.email")}</label>
          <Input disabled={true} id="email" value={this.state.email} />
        </Form.Field>
        <Form.Field inline width={4} error={!emailOK}>
          <label htmlFor={"email_new"}>{t("forms.profile.email_new")}</label>
          <Input
            id="email_new"
            value={this.state.email_new || ""}
            onChange={this.onChange}
          />
        </Form.Field>
      </Form.Group>
    );

    formGroups.push(
      <Form.Group key="lang" style={{ marginTop: "0.5em" }}>
        <Form.Field key={"language"} width={4}>
          <label>{t("forms.profile.language")}</label>
          <span className="DRlanguage">{t("languageLong")}</span>
          <Button className={enClass} onClick={() => this.setLang("en")} />
          <Button className={deClass} onClick={() => this.setLang("de")} />
        </Form.Field>
        <Form.Field width={12} className="view" style={{ textAlign: "right" }}>
          <DrSaveButton
            disabled={!changed || !nameOk || !emailOK || this.state.inRequest}
            text={t("forms.profile.save")}
            onClick={this.saveProfile}
          />
        </Form.Field>
      </Form.Group>
    );

    formGroups.push(
      <Form.Group key="roles" style={{ marginTop: "0.5em" }}>
        <Form.Field
          inline
          width={16}
          className="view"
          style={{ backgroundColor: "transparent" }}
        >
          <label htmlFor={"roles"}>{t("forms.profile.roles")}</label>
          <div
            style={{
              width: "20rem",
              borderRight: "1px solid #aaaaaa",
              borderBottom: "1px solid #aaaaaa"
            }}
          >
            {rolesDisplay}
          </div>
        </Form.Field>
        <Form.Field
          inline
          width={8}
          style={{ backgroundColor: "rgb(217, 232, 235)" }}
        >
          <label>{t("forms.profile.special_rights_tags")}</label>
          <div>
            <div
              style={{
                backgroundColor: "rgb(217, 232, 235)",
                border: "1px solid #dddddd"
              }}
            >
              {taglist}
            </div>
          </div>
        </Form.Field>
      </Form.Group>
    );

    let autocomplete = "new-password";

    return (
      <Container
        style={{
          paddingTop: "2rem",
          backgroundColor: "rgb(217, 232, 235)",
          width: "100%",
          padding: "0.25em 2em",
          marginTop: "-3px"
        }}
      >
        {false ? <h1>{t("forms.profile.title")}</h1> : ""}
        <Form autoComplete={autocomplete}>{formGroups}</Form>
        <div
          style={{
            position: "absolute",
            bottom: "0.25em",
            right: "0.75em",
            fontSize: "0.8em",
            backgroundColor: "transparent"
          }}
        >
          <div style={{ textAlign: "right", backgroundColor: "transparent" }}>
            {"Version: " + moment(this.context.build).format("L HH:mm")}
          </div>
          <div style={{ textAlign: "right", backgroundColor: "transparent" }}>
            {"Screen: [" +
              this.state.screen.w +
              "*" +
              this.state.screen.h +
              "]"}
          </div>
        </div>
      </Container>
    );
  }
}

ProfileImpl.contextType = UserContext;
const Profile = withTranslation()(ProfileImpl);
export { Profile };
