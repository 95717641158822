import React from "react";

class Message extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { show: true };
  }

  close = () => {
    this.setState({ show: false }, this.deleteMessage);
  };

  deleteMessage = () => {
    if (this.props.deleteMessage) {
      this.props.deleteMessage();
    }
  };

  render() {
    let text = this.props.text;

    try {
      text = (
        <div
          style={{ whiteSpace: "break-spaces", backgroundColor: "transparent" }}
        >
          {JSON.stringify(text, null, 2)
            .split('"')
            .join("")}
        </div>
      );
    } catch (e) {
      text = <span>{text}</span>;
    }

    if (this.state.show) {
      return (
        <div
          className={this.props.className ? this.props.className : "message"}
        >
          <span
            style={{
              position: "absolute",
              top: "4px",
              right: "4px",
              color: "#ffffff",
              padding: "2px 4px",
              border: "1px solid white",
              cursor: "pointer"
            }}
            onClick={this.close}
          >
            X
          </span>
          {text ? text : "message"}
        </div>
      );
    } else {
      return "";
    }
  }
}
export { Message };
