import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
import { withRouter } from "./helpers/withRouter";
import { withTranslation } from "react-i18next";
import { Menu, Icon } from "semantic-ui-react";
import { UserContext } from "./helpers/UserContext.jsx";
import config from "./../config/menuConfig.json";

const StyledMenue = styled(Menu)`
  position: relative;
  top: -3px;
  margin-top: 0.25rem !important;
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
  background-color: #f0f0f0 !important;
  margin-bottom: ${props =>
    props.marginbottom
      ? props.marginbottom + "rem !important"
      : "0rem !important"};
`;

const goHome = function() {
  let href;
  let tmp = window.location.href.split(".digital-result.cloud/");

  if (tmp.length > 1) {
    // 'select app' application is served from [customer].digital-result.cloud/apps
    // we replace /apps/ by new app
    href = tmp[0] + ".digital-result.cloud";
    window.history.pushState({}, "", window.location.href);
    window.location.replace(href);
  } else {
    // test local
    console.log("local: kein wechsel location");
  }
};

class HeaderImpl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      config: config,
      width: null
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    let w = "wide";
    if (window.innerWidth < 980) {
      w = "small";
    }
    if (this.state.width !== w) {
      this.setState({ width: w });
    }
  };

  doLink = to => {
    this.props.history.push(to);
  };

  menuExpand = e => {
    e.stopPropagation();
    this.setState({ menuExpanded: true });
  };

  menuCollapse = e => {
    e.stopPropagation();
    this.setState({ menuExpanded: false });
  };

  getText(menu) {
    let v = menu.id;
    if (menu.value) {
      let propPath = menu.value.split(".");
      v = this.context;
      for (let i = 0; i < propPath.length; i++) {
        let p = propPath[i];
        v = v[p];
        if (!v && menu.valueAlt) {
          propPath = menu.valueAlt.split(".");
          v = this.context;
          for (let i = 0; i < propPath.length; i++) {
            let p = propPath[i];
            v = v[p];
          }
        }
      }
    }
    return v;
  }

  render() {
    const { t } = this.props;

    const path = window.location.pathname;

    let translations = [];
    let labels = this.context.labels
      ? this.context.labels.find(e => {
          return (e.language = t("language"));
        })
      : null;
    if (labels) {
      translations = labels.translations;
    }

    let menuEntries = [
      <span
        key="logo"
        className="applicationtitle"
        title={t("build") + moment(this.context.build).format("L HH:mm")}
        onClick={goHome}
        style={{ cursor: "pointer" }}
      >
        {t("application.name")}
      </span>
    ];
    for (let iMenu = 0; iMenu < this.state.config.menu.length; iMenu++) {
      let menuItem = this.state.config.menu[iMenu];
      let pos = menuItem.position ? menuItem.position : null;
      let show = this.context.rights.menu.indexOf(menuItem.id) >= 0;

      if (show) {
        let text = menuItem.value
          ? t(this.getText(menuItem))
          : t("menu." + this.getText(menuItem));
        let title = menuItem.value
          ? t(this.getText(menuItem))
          : t("menu.title." + this.getText(menuItem));
        if (translations["tab_" + this.getText(menuItem)]) {
          text = translations["tab_" + this.getText(menuItem)];
        }
        let active = menuItem.to === path;

        if (menuItem.id === "profile") {
          menuEntries.push(
            <Menu.Item
              key={"menuitem_" + menuItem.id}
              position={pos}
              active={active}
              className="profile"
            >
              <Link
                to={menuItem.to}
                className={menuItem.id}
                title={t("menu.profile_for") + text}
              >
                {text + " "}
                <Icon name="user" />
              </Link>
              <span
                style={{
                  marginLeft: "1rem",
                  cursor: "pointer"
                }}
                title={t("menu.logout")}
                onClick={() => {
                  this.context.logout("logout");
                }}
              >
                <Icon name="log out" color="red" />
              </span>
            </Menu.Item>
          );
        } else {
          if (active) {
            menuEntries.push(
              <Menu.Item
                key={"menuitem_" + menuItem.id}
                position={pos}
                active={active}
                style={{
                  color: "#0000ff",
                  borderBottom: "1px solid transparent",
                  marginBottom: "-1px",
                  paddingTop: "16px",
                  fontWeight: "normal"
                }}
              >
                {text}
              </Menu.Item>
            );
          } else {
            menuEntries.push(
              <Menu.Item
                key={"menuitem_" + menuItem.id}
                as={Link}
                title={title}
                to={menuItem.to}
                position={pos}
                active={active}
                style={{
                  borderBottom: "1px solid #d4d4d5",
                  marginBottom: "-1px"
                }}
              >
                {text}
              </Menu.Item>
            );
          }
        }
      }
    }
    let html;

    if (this.state.width === "wide") {
      html = (
        <StyledMenue className="topMenu desktop" tabular stackable={true}>
          {menuEntries}
        </StyledMenue>
      );
    } else {
      html = (
        <>
          <div className={"mobileMargin "} />
          <div
            className={
              this.state.menuExpanded
                ? "mobileMenuContainer expanded"
                : "mobileMenuContainer"
            }
            onClick={
              this.state.menuExpanded ? this.menuCollapse : this.menuExpand
            }
          >
            <Menu
              className="topMenu mobile"
              vertical
              tabular
              position={"right"}
              direction={"left"}
              style={{ margin: 0 }}
            >
              {menuEntries}
            </Menu>
            <div
              onClick={this.menuCollapse}
              style={{
                position: "absolute",
                top: "0px",
                right: "0px",
                display: "inline-block",
                cursor: "pointer",
                backgroundColor: "transparent"
              }}
            >
              <Icon
                link
                name="close"
                color="red"
                style={{ backgroundColor: "transparent" }}
              />
            </div>
          </div>
        </>
      );
    }
    return html;
  }
}

HeaderImpl.contextType = UserContext;
const routedHeader = withRouter(HeaderImpl);
delete routedHeader.contextType; // https://stackoverflow.com/questions/53240058/use-hoist-non-react-statics-with-withrouter
const Header = withTranslation()(routedHeader);
export { Header };
