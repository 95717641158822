import React from "react";
import { withTranslation } from "react-i18next";
import { Popup, Button, Icon } from "semantic-ui-react";
import { UserContext } from "./../UserContext.jsx";

class DrSaveButtonImpl extends React.Component {
  /*
   * decorator for save Button
   * props:
   *    popup: Text in Popup
   *    disabled: disabled
   *    onClick: onClick Function
   *    params: Parameter for onClick
   *    text: text in Button
   */

  render() {
    let disabled = this.props.disabled;
    let className = "saveButton";
    if (this.props.extraClass) {
      className += " " + this.props.extraClass;
    }

    if (disabled) {
      return (
        <Popup
          position="left center"
          disabled={!this.props.popup}
          offset={[0, 14]}
          style={{
            margin: "0",
            padding: "0",
            backgroundColor: "#cc0000",
            color: "#ffffff"
          }}
          hoverable
          content=<div className="popup">{this.props.popup}</div>
          trigger={
            <div style={{ display: "inline" }}>
              <Button
                className="saveButton disabled"
                style={{
                  cursor: "not-allowed"
                }}
              >
                <Icon name={this.props.icon ? this.props.icon : "save"} />
                {this.props.text}
              </Button>
            </div>
          }
        />
      );
    } else {
      return (
        <Button
          className={className}
          onClick={e => {
            if (typeof this.props.params !== "undefined") {
              this.props.onClick(this.props.params);
            } else {
              this.props.onClick();
            }
          }}
        >
          <Icon name={this.props.icon ? this.props.icon : "save"} />
          {this.props.text}
        </Button>
      );
    }
  }
}
DrSaveButtonImpl.contextType = UserContext;
const DrSaveButton = withTranslation()(DrSaveButtonImpl);
export { DrSaveButton };
