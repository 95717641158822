import React from "react";
import { withRouter } from "../helpers/withRouter";
import { withTranslation } from "react-i18next";
import request from "./../helpers/request.js";
import { Button, Form } from "semantic-ui-react";
import { Password } from "./Password.jsx";
import { Message } from "../helpers/Message";
import { Activate } from "./Activate.jsx";
import { PwReset } from "./PwReset.jsx";
import logo from ".././../css/img/dr.png";

class LoginImpl extends React.PureComponent {
  constructor(props) {
    super(props);

    this.accountRef = React.createRef();
    this.passwordRef = React.createRef();

    let activationkey = "";
    let activationuser = "";
    if (window.location.search) {
      let params = new URLSearchParams(window.location.search);
      if (params.has("activationkey")) {
        activationkey = params.get("activationkey");
      }
      if (params.has("name")) {
        activationuser = params.get("name");
      }
    }

    this.state = {
      name: "",
      password: "",
      formMessage: "",
      changed: false,
      activationkey: activationkey,
      activationuser: activationuser,
      inputOk: { name: false, password: false },
      pwResetFocus: false,
      logoUrl: null
    };
  }

  componentDidUpdate() {
    if (window.location.search) {
      let params = new URLSearchParams(window.location.search);
      if (!params.has("activationkey")) {
        this.setState({ activationkey: "" });
      }
    }

    if (!this.state.logoUrl) {
      this.requestLogo()
    }
  }

  componentDidMount() {
    this.setState({
      name: "",
      password: "",
      changed: false
    });
  }

  requestLogo = () => {
    request(
      "POST",
      "usr/logos",
      { description: "Logo Login" },
      (result) => {
        this.setState({ logoUrl: result.result || logo });
      },
      (error) => {
        console.log("Error:", error);
      }
    );
  };

  handleRedirectToApp = (user) => {
    const params = new URLSearchParams(window.location.search);
    const redirectTo = params.get("redirectTo");

    if (!redirectTo || user.customerroles.length === 0) {
      this.props.setUser(user)
      return;
    }

    let redirectToDecoded = decodeURIComponent(redirectTo);

    let userHasRolesForApps = user.customerroles.map(roleForApp => roleForApp.app === 'Administration' ? 'adm' : roleForApp.app.toLowerCase())

    let allowRedirectTo = userHasRolesForApps.some(app => {
      const isMatch = redirectToDecoded.match(/\/?([^/]+)/) // match the appname in the URL: /pro/ -> "pro"
      return isMatch && app === isMatch[1].toLowerCase();
    })

    if (allowRedirectTo) {
      window.location.replace(`${window.location.origin}${redirectToDecoded.startsWith('/') ? '' : '/'}${redirectToDecoded}`);
    } else {
      console.warn("Redirect not allowed. It is either not a valid app name or the user has no matching roles.");

      const url = new URL(window.location);
      url.searchParams.delete('redirectTo');
      window.history.replaceState({}, document.title, url.toString());
    }
  }

  handleLogin = user => {
    this.props.setSession(user);
    this.handleRedirectToApp(user);
  };

  handleLoginError = error => {
    console.log("handleLoginError", error);
    this.setState({
      formMessage: this.props.t("forms.user.login.error." + error.status)
    });
  };

  handleSubmit = () => {
    let name = this.state.name.trim();
    let password = this.state.password.trim();
    request(
      "POST",
      "usr/login",
      { name: name, password: password },
      this.handleLogin,
      this.handleLoginError
    );
  };

  handleChange = (e, input) => {
    let name = this.state.name;
    let password = this.state.password;
    switch (input.id) {
      case "dr-account":
        name = input.value;
        break;
      case "dr-password":
        password = input.value;
        break;
      default:
        console.log("handleChange - wrong id", input.id);
    }

    this.setState({
      name: name,
      password: password,
      changed: true,
      formMessage: ""
    });
  };

  validPassword = pw => {
    let rPw = /^[^:#?@]{8,20}$/;
    let match = pw.match(rPw);
    if (match && match[0] === pw) {
      return true;
    }
    return false;
  };

  setAccountName = name => {
    this.setState(
      { name: name },
      this.passwordRef.current.children[0].children[1].children[0].focus()
    );
  };

  render() {
    const { t } = this.props;

    let msg = "";
    if (this.props.text && this.props.text.length > 0) {
      msg = <Message text={this.props.text} className={"message warning"} />;
    }

    let autocomplete = "new-password";
    autocomplete = "on";

    return (
      <div className="login" key={"LoginForm_" + this.state.ts}>
        {this.state.logo &&
          <img className="customimg" src={this.state.logoUrl} alt="Logo"/>
        }
        <div className="applicationname">{t("application.name")}</div>
        {msg}
        <Form className="login" autoComplete={autocomplete}>
          <Form.Group>
            <Form.Field width={16}>
              <div ref={this.accountRef}>
                <Form.Input
                  id={"dr-account"}
                  fluid
                  label={t("forms.user.login.label.account")}
                  placeholder=""
                  tabIndex="1"
                  value={this.state.name}
                  onChange={this.handleChange}
                  required={true}
                />
              </div>
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={16}>
              <div ref={this.passwordRef}>
                <Password
                  id={"dr-password"}
                  label={t("forms.user.login.label.password")}
                  placeholder={""}
                  tabIndex="2"
                  value={this.state.password}
                  onChange={this.handleChange}
                  onBlur={null}
                />
              </div>
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={"sixteen"}>
              <Button
                fluid
                primary
                disabled={
                  this.state.formMessage.length > 0 ||
                  !this.validPassword(this.state.password)
                }
                type="submit"
                tabIndex="2"
                onClick={this.handleSubmit}
              >
                {t("forms.user.login.button.login")}
              </Button>
              <hr/>
              <PwReset hasFocus={this.state.pwResetFocus}/>
              <Activate
                activationkey={this.state.activationkey}
                user={this.state.activationuser}
                onOK={name => {
                  this.setAccountName(name);
                }}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        {this.state.formMessage.length > 0 ? (
          <Message text={this.state.formMessage} className={"message error"}/>
        ) : (
          ""
        )}
        <div className="build">{this.props.build}</div>
      </div>
    );
  }
}

const LoginRouted = withRouter(LoginImpl);
delete LoginRouted.contextType; // https://stackoverflow.com/questions/53240058/use-hoist-non-react-statics-with-withrouter
const Login = withTranslation()(LoginRouted);
export { Login };
